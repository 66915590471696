import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation';
import Body from './Body';
import Footer from './Footer';

const Layout = (props) => {
  const { children, isGradient } = props;

  return (
    <>
      <Navigation isHomepage={isGradient} />
      <div className="flex flex-col min-h-screen">
        <Body isHomepage={isGradient}>{children}</Body>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isGradient: PropTypes.bool.isRequired,
};

export default Layout;
