import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from '../../utility/axios';
import AppContext from '../../contexts/AppContext';
import { clearAuthCookies } from '../../utility/utils';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const { setIsAuthenticated } = useContext(AppContext);

  const getUser = async () => {
    let response;

    try {
      response = await axios.get('/');
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          toast.error('You are not authorized!');
          clearAuthCookies();
          setIsAuthenticated(false);
          history.push('/login');
        } else {
          toast.error('Something went wrong! Please try again later');
        }
      } else {
        toast.error('Network error occurred. Please check your internet connection and try again.');
      }
    }
    if (response && response.status === 200) {
      setUser(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, []);

  return (
    <div>
      <p className="text-emerald-950">This is the dashboard page</p>
      <p> Email: {user?.email}</p>
    </div>
  );
};

Dashboard.displayName = 'Dashboard';

export default Dashboard;
