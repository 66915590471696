import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TextField = (props) => {
  const {
    element,
    variant,
    label,
    type,
    id,
    name,
    value,
    onChange,
    required,
    disabled,
    placeholder,
    fullWidth,
    isDirty,
    isValid,
    autoComplete,
    autoFocus,
  } = props;
  const isDarkVariant = variant === 'dark';
  const isError = isDirty && !isValid;

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label htmlFor={id} className={cn({ 'text-white': !isDarkVariant })}>
          {label}{' '}
          {required && (
            <span className={cn({ 'text-red-700': isDarkVariant, 'text-white': !isDarkVariant })}>
              *
            </span>
          )}
        </label>
      )}
      {React.cloneElement(element, {
        id,
        className: cn('text-black p-4 rounded-lg', {
          'w-full': fullWidth,
          'border border-gray-800': isDarkVariant && !isError,
          'border border-red-700': isError,
        }),
        name,
        type,
        value,
        onChange,
        placeholder,
        disabled,
        autoComplete,
        autoFocus,
      })}
    </div>
  );
};

TextField.propTypes = {
  element: PropTypes.element.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  isDirty: PropTypes.bool,
  isValid: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
};

TextField.defaultProps = {
  variant: 'dark',
  label: '',
  value: '',
  type: 'text',
  required: false,
  disabled: false,
  placeholder: '',
  fullWidth: true,
  isDirty: false,
  isValid: true,
  autoComplete: 'off',
  autoFocus: false,
};

TextField.displayName = 'TextField';

export default TextField;
