import React from 'react';

const CLIENTS = [
  {
    id: 1,
    src: 'images/clients/7n-logo.webp',
    alt: '7N Logo',
    link: {
      text: '7n.com',
      url: 'https://www.7n.com/',
    },
    name: '7N',
    slug: '7n',
    experiences: [
      {
        id: 1,
        role: 'Senior Python Full-Stack Developer',
        startDate: 'JAN 2023',
        endDate: 'Present',
        project: '3D GeoContext Modelling App',
        description: (
          <span>
            The client, SLB (Schlumberger NV), leader in providing technology in oil industry, was
            trying to extend the portfolio of clients, but was struggling due to lack of support for
            abstract connections with different data warehouses.
          </span>
        ),
        solutions: [
          <span key={1}>
            Redesign existing workflows to use an external package used for integrating multiple
            data warehouses rather than working directly with the storage.
          </span>,
          <span key={2}>
            Increase code coverage from <strong>43% to 70%</strong>, by solidifying existing
            codebase with unit tests and E2E tests.
          </span>,
        ],

        skills: ['Azure DevOps', 'Argo Workflows', 'Python'],
      },
    ],
  },
  {
    id: 2,
    src: 'images/clients/siit-logo.png',
    alt: 'Informal School of IT Logo',
    link: {
      text: 'scoalainformala.ro',
      url: 'https://scoalainformala.ro/',
    },
    name: 'Scoala Informala de IT',
    slug: 'scoala-informala-de-it',
    experiences: [
      {
        id: 1,
        role: 'Senior Python Developer (Mentor)',
        startDate: 'FEB 2020',
        endDate: 'Present',
        project: 'Python Development',
        description: (
          <span>
            The client, providing IT courses for non-technical people, was aiming to help clients
            acquire Junior positions at IT companies and to develop a partnership with Google
            regarding Google Digital Garage, but was struggling to do so, due to lacking mentors.
          </span>
        ),
        solutions: [
          <span key={1}>
            Helped <strong>50% of the attendees</strong> to make a change in their careers, by using
            a highly-intuitive framework to get them from the basics of programming to designing an
            application exposed to a web interface using Python.
          </span>,
          <span key={2}>
            Successfully mentored and helped <strong>up to 40 students</strong> from Romanian
            technical universities to get a high overview of a Python application and achieve a
            diploma from Google&apos;s program.
          </span>,
        ],
        skills: [
          'Software Development',
          'Git',
          'Python',
          'SQL',
          'MySQL',
          'GraphQL',
          'Django',
          'Alembic',
          'SQLAlchemy',
          'OOP',
        ],
      },
    ],
  },
  {
    id: 3,
    src: 'images/clients/sftl-brand.png',
    alt: 'Softelligence Logo',
    link: {
      text: 'softelligence.net',
      url: 'http://softelligence.net/',
    },
    name: 'Softelligence',
    slug: 'softelligence',
    experiences: [
      {
        id: 1,
        role: 'Senior Python Full-Stack Developer',
        startDate: 'AUG 2021',
        endDate: 'DEC 2022',
        project: 'Online E-learning platform',
        description: (
          <span>
            The client, leader in the education system in the US, was aiming to connect teachers and
            students for taking online assignments for AP & Pre-AP programs and extend the learning
            process in the online world, but was unable to do so, due to lacking an online platform.
          </span>
        ),
        solutions: [
          <span key={1}>
            Ensured <strong>100% platform stability and novelty</strong> to{' '}
            <strong>3M+ students and 200k+ teachers all over US</strong>, by decreasing the number
            of reported bugs and designing the new features implemented quarterly.
          </span>,
          <span key={2}>
            Saved up to 50% of the time teachers were spending in finding correcting questions, by
            designing a smart solution of indexing data in Elasticsearch for questions filtering.
          </span>,
        ],
        skills: [
          'Python',
          'Flask',
          'Elasticsearch',
          'Amazon Web Services (AWS)',
          'MySQL',
          'GraphQL',
          'React',
          'react-apollo',
          'react-redux',
          'Jest',
          'enzyme',
          'TailwindCSS',
          'Git',
          'GitHub',
          'Webpack',
        ],
      },
    ],
  },
];

export default CLIENTS;
