import React from 'react';
import PropTypes from 'prop-types';

const HeroButton = (props) => {
  const { children, onClick } = props;

  return (
    <button
      className="text-2xl font-fancy font-bold uppercase hover:scale-110 mx-auto md:mx-0 bg-secondary hover:bg-secondary-light text-gray-800 rounded-xl py-6 px-10 shadow-2xl focus:outline-none focus:shadow-outline"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

HeroButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

HeroButton.displayName = 'HeroButton';

export default HeroButton;
