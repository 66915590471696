import axios from 'axios';
import { getCookie } from './utils';

const { AUTH_SERVICE_ENDPOINT } = process.env;

const axiosInstance = axios.create({
  baseURL: AUTH_SERVICE_ENDPOINT,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getCookie(document.cookie, 'access_token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const { status, statusText } = error.response;
    if (status === 401 && statusText === 'Access token expired') {
      let refreshResponse;

      try {
        refreshResponse = await axiosInstance.post('/refresh/');
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }

      if (refreshResponse.status === 200) {
        originalRequest.headers.Authorization = getCookie(document.cookie, 'access_token');
        return axiosInstance.request(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
