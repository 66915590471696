import React, { useContext } from 'react';
import pick from 'lodash/pick';
import AppContext from '../../contexts/AppContext';
import SectionWithTitle from '../Layout/SectionWithTitle';
import { EN_ABOUT_LINK, RO_ABOUT_LINK } from '../../constants/links';

const About = () => {
  const { isRo } = useContext(AppContext);
  const { text, hash } = pick(isRo ? RO_ABOUT_LINK : EN_ABOUT_LINK, ['text', 'hash']);

  return (
    <SectionWithTitle id={hash.slice(1)} className="bg-white" title={text}>
      <div className="flex flex-col items-center md:flex-row gap-8 md:gap-32 mx-auto">
        <div className="flex flex-col gap-8 md:gap-16 max-w-lg">
          <h3 className="font-fancy text-3xl text-primary font-bold leading-none">
            {isRo ? 'Cine suntem?' : 'Who we are?'}
          </h3>
          <p className="text-gray-600 text-justify">
            {isRo ? (
              <>
                Povestea noastră a început în anul <strong>2020</strong> și de atunci dezvoltăm
                aplicații software personlizate și servicii de consultanță web de înaltă calitate.
              </>
            ) : (
              <>
                Our journey began in <strong>2020</strong> and from back then we designed
                custom-tailored software and provide extraordinary consulting services in
                applications served through a web interface.
              </>
            )}
          </p>
          <p className="text-gray-600 text-justify">
            {isRo ? (
              <>
                Experiența vastă ne permite cercetarea în detaliu a cerințelor clienților pentru
                definirea și dezvoltarea etapelor necesare obținerii rezultatelor dorite.
              </>
            ) : (
              <>
                Our extensive expertise allows us to in-depth research your requirements and design
                a multiple stage based solution for bringing your dreams to life.
              </>
            )}
          </p>
          <p className="text-gray-600 text-justify">
            {isRo ? (
              <>
                Ne ajutăm clienții în mod activ să-și mărească veniturile și să-și îmbunatățească
                poziționarea pe piață sau să le ofere clienților lor cea mai bună experiență
                posibilă.
              </>
            ) : (
              <>
                We actively help our clients to increase their income and market positioning or
                provide their clients with the best experience they can ever get.
              </>
            )}
          </p>
          <p className="text-right text-primary font-medium italic">Mihai Vladu</p>
        </div>
        <div className="w-full md:w-140">
          <img className="rounded-xl" src="images/mihai-vladu.webp" />
        </div>
      </div>
    </SectionWithTitle>
  );
};

export default About;
