import React, { useContext } from 'react';
import cn from 'classnames';
import pick from 'lodash/pick';
import { EN_SKILLS_LINK, RO_SKILLS_LINK } from 'constants/links';
import AppContext from '../../contexts/AppContext';
import SectionWithTitle from '../Layout/SectionWithTitle';
import Card from '../ui/Card';

const getSkills = (isRo, isWhiteTheme = false) => [
  {
    id: 1,
    imageUrl: 'images/technologies/javascript-logo.webp',
    title: 'JavaScript',
    subtitle: 'React, VueJS, Angular, jQuery',
    content: isRo ? (
      <>
        Suntem experți când vine vorba de JavaScript și folosirea unuia dintre cele mai populare
        framework-uri de JS,{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>React</strong>. De asemenea
        suntem familiari și cu{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>VueJS</strong> și dornici să
        ne jucăm cu <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Angular</strong>
        .
      </>
    ) : (
      <>
        We’re experts in working with JavaScript and dealing with one of the most known JS
        framework, <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>React</strong>.
        Beside that we’re also familiar with{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>VueJS</strong> and eager to
        start playing around in{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Angular</strong>.
      </>
    ),
  },
  {
    id: 2,
    imageUrl: 'images/technologies/python-logo.webp',
    title: 'Python',
    subtitle: 'Django, Flask, FastAPI',
    content: isRo ? (
      <>
        Fie că ai deja o aplicație dezvoltată în{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Django</strong>,{' '}
        <strong>Flask</strong> sau{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>FastAPI</strong> sau vrei să
        începi una nouă folosind cel mai puternic limbaj de programare al momentului, suntem pe
        aceeași lungime de undă.
      </>
    ) : (
      <>
        Even if you already have an application in{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Django</strong>,{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Flask</strong> or{' '}
        <strong className={cn({ 'text-secondary-dark': isWhiteTheme })}>Fast API</strong> or you’d
        like to start one using one of the most powerful programming language of the moment, we’re
        on the same page.
      </>
    ),
  },
  {
    id: 3,
    imageUrl: 'images/technologies/aws-logo.webp',
    title: 'Amazon Web Services',
    content: isRo ? (
      <>
        Un proiect bun necesită și o infrastructură puternică și sigură, dar în același timp ușor de
        folosit. Hai să setăm totul așa cum este cel mai bine pentru tine.
      </>
    ) : (
      <>
        A good project needs a powerful, secure and easy to use infrastructure. Let’s setup things
        the way you need.
      </>
    ),
  },
  {
    id: 4,
    imageUrl: 'images/technologies/online-payments-logo.webp',
    title: isRo ? 'Plăți online' : 'Online payments',
    subtitle: 'Stripe, Netopia',
    content: isRo ? (
      <>
        În zilele acestea plățile online sunt mai sigure ca oricând. Putem vorbi fie de Stripe dacă
        ești client internațional sau de Netopia dacă afacerea ta se bazează pe piața din România.
      </>
    ) : (
      <>
        Nowadays online payments are more secure and used than ever. We already talk Stripe most
        likely if you’re an international client/business or Netopia if you’re Romanian.
      </>
    ),
  },
  {
    id: 5,
    imageUrl: 'images/technologies/elasticsearch-logo.webp',
    title: 'Elasticsearch',
    content: isRo ? (
      <>
        Unele aplicații necesită motoare interne de căutare avansate pentru conținutul acestora. Ne
        putem folosi de super puterea acestei tehnologii.
      </>
    ) : (
      <>
        Some applications need an advanced way of searching in their content or products
        information. We can leverage this to this amazing tool.
      </>
    ),
  },
  {
    id: 6,
    imageUrl: 'images/technologies/wordpress-logo.webp',
    title: 'WordPress',
    content: isRo ? (
      <>
        Nu ai nevoie decât să devi vizibil online? Nici o problemă, putem foarte ușor să setăm un
        site de prezentare cu ajutorul Wordpress la cele mai mici costuri posibile.
      </>
    ) : (
      <>
        No need for a rocket science online presence? Now worries, we can easily start up your
        website using WordPress at the lowest possible cost.
      </>
    ),
  },
];

const Skills = () => {
  const { isRo } = useContext(AppContext);
  const { text: title, hash } = pick(isRo ? RO_SKILLS_LINK : EN_SKILLS_LINK, ['text', 'hash']);
  const skills = getSkills(isRo, true);

  return (
    <SectionWithTitle isColorTheme id={hash.slice(1)} title={title}>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 md:gap-32">
        {skills.map(({ id, imageUrl, title, subtitle, content }) => (
          <Card isWhiteTheme key={id} imageUrl={imageUrl} title={title} subtitle={subtitle}>
            {content}
          </Card>
        ))}
      </div>
    </SectionWithTitle>
  );
};

export default Skills;
