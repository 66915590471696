import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';

const roParagraphs = [
  {
    title: 'Ce sunt datele confidențiale?',
    content: [
      <>
        Datele confidențiale reprezintă orice informație care vă poate identifica pe dumneavoastră
        ca persoană. Ex: nume și prenume, adresa de e-mail ș.a.
      </>,
    ],
  },
  {
    title: 'Ce date prelucrăm? De ce?',
    content: [
      <>
        Informațiile confidențiale pe care DACIAN TECHNOLOGIES S.R.L. le prelucrează prin
        intermediul acestei aplicații web sunt date generale cu ajutorul cărora putem comunica
        online sau offline cu dumneavoastră.
      </>,
      <>
        Cu toate ca tehnic avem posibilitatea să stocăm aceste informații pe serverele noastre, vă
        asigurăm că în acest moment nu o facem. Singurele informații pe care le prelucrăm sunt cele
        din formularul de{' '}
        <Link
          className="text-primary underline"
          to={{
            pathname: '/ro',
            hash: 'contact',
          }}
        >
          Contact
        </Link>
        . Nevoia de a prelucra aceste informații este strict pentru a le trimite mai departe, pe
        e-mail, către echipa DACIAN TECHNOLOGIES S.R.L. astfel încât colegii noștri să vă poată
        contacta în vederea unei posibile colaborări.
      </>,
    ],
  },
  {
    title: 'Cât timp sunt stocate datele?',
    content: [
      <>
        Nu stocăm aceste informații sub nici o formă posibilă (baze de date, fișiere, log-uri etc.).
      </>,
    ],
  },
];

const enParagraphs = [
  {
    title: 'What is private data?',
    content: [
      <>
        Private data is any type of information that can lead in identifying your person. E.g.:
        first name and last name, e-mail address et al.
      </>,
    ],
  },
  {
    title: 'What data do we gather? Why?',
    content: [
      <>
        Private data gathered by DACIAN TECHNOLOGIES S.R.L. through this web application are general
        information about you through which we can communicate with you online or offline.
      </>,
      <>
        Although from a technical perspective we have the possibility to store this information on
        our servers, we assure you we don&apos;t do it in this moment. The only data gathered are
        the ones from the{' '}
        <Link
          className="text-primary underline"
          to={{
            pathname: '/',
            hash: 'contact',
          }}
        >
          Contact
        </Link>{' '}
        form. We need this information only for sending them further, on e-mail, to our team at
        DACIAN TECHNOLOGIES S.R.L. so that our colleagues can contact you regarding a possible
        collaboration.
      </>,
    ],
  },
  {
    title: 'How long is the data stored?',
    content: [
      <>We don&apos;t store gathered data under any possible form (databases, files, logs etc.).</>,
    ],
  },
];

const Privacy = () => {
  const { isRo } = useContext(AppContext);

  return (
    <div className="flex flex-col gap-y-8">
      <h1 className="text-4xl text-primary font-medium">
        {isRo ? 'Politică de Confidențialitate (GDPR)' : 'Privacy Policy (GDPR)'}
      </h1>
      {(isRo ? roParagraphs : enParagraphs).map(({ title, content }, index) => (
        <div key={index} className="flex flex-col gap-y-2">
          <h2 className="text-3xl text-black font-medium">{title}</h2>
          {content.map((text, contentIndex) => (
            <p key={contentIndex}>{text}</p>
          ))}
        </div>
      ))}
      <hr />
      {isRo ? (
        <p>
          Folosirea acestei aplicații web reprezintă acceptul dumneavoastră în legătura cu folosirea
          datelor confidențiale de către DACIAN TECHNOLOGIES S.R.L. în scopurile menționate
          anterior.
        </p>
      ) : (
        <p>
          The use of this web application represents your approval about using your private data by
          DACIAN TECHNOLOGIES S.R.L. in order to achieve previously mentioned scopes.
        </p>
      )}
      <hr />
      <p className="text-right italic">
        {isRo ? 'Ultima actualizare: 01-09-2022' : 'Last update: 2022-09-01'}
      </p>
    </div>
  );
};

export default Privacy;
