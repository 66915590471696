export const EN_ABOUT_LINK = {
  id: 1,
  text: 'About',
  pathname: '/',
  hash: '#about',
};

export const EN_CLIENTS_LINK = {
  id: 2,
  text: 'Clients',
  pathname: '/',
  hash: '#clients',
};

export const EN_SERVICES_LINK = {
  id: 3,
  text: 'Services',
  pathname: '/',
  hash: '#services',
};

export const EN_SKILLS_LINK = {
  id: 4,
  text: 'Skills',
  pathname: '/',
  hash: '#skills',
};

export const EN_CONTACT_LINK = {
  id: 5,
  text: 'Contact',
  pathname: '/',
  hash: '#contact',
};

export const RO_ABOUT_LINK = {
  id: 1,
  text: 'Despre',
  pathname: '/ro',
  hash: '#despre',
};

export const RO_CLIENTS_LINK = {
  id: 2,
  text: 'Clienți',
  pathname: '/ro',
  hash: '#clienti',
};

export const RO_SERVICES_LINK = {
  id: 3,
  text: 'Servicii',
  pathname: '/ro',
  hash: '#servicii',
};

export const RO_SKILLS_LINK = {
  id: 4,
  text: 'Tehnologii',
  pathname: '/ro',
  hash: '#tehnologii',
};

export const RO_CONTACT_LINK = {
  id: 5,
  text: 'Contact',
  pathname: '/ro',
  hash: '#contact',
};

export const LINKS = {
  en: [EN_ABOUT_LINK, EN_CLIENTS_LINK, EN_SERVICES_LINK, EN_SKILLS_LINK, EN_CONTACT_LINK],
  ro: [RO_ABOUT_LINK, RO_CLIENTS_LINK, RO_SERVICES_LINK, RO_SKILLS_LINK, RO_CONTACT_LINK],
};

export const RO_PRIVACY_POLICY_LINK = {
  text: 'Politică de Confidențialitate',
  link: '/ro/politica-de-confidentialitate',
};

export const EN_PRIVACY_POLICY_LINK = {
  text: 'Privacy Policy',
  link: '/privacy-policy',
};

export const RO_COOKIES_POLICY_LINK = {
  text: 'Politică de Cookies',
  link: '/ro/politica-de-cookies',
};

export const EN_COOKIES_POLICY_LINK = {
  text: 'Cookies Policy',
  link: '/cookies-policy',
};
