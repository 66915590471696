import React, { useContext } from 'react';
import AppContext from '../../contexts/AppContext';

const roParagraphs = [
  {
    title: 'Date generale',
    content: [
      <p>
        Pentru menținerea aplicației web și pentru a asigura funcționalitatea acesteia în mod
        corespunzător, DACIAN TECHNOLOGIES S.R.L. utilizează tehnologia cunoscută în general sub
        numele de “cookie”-uri. Cookie-urile sunt fișiere mici pe care le trimitem pe calculatorul
        tău și le putem accesa mai târziu. Acestea pot fi temporare sau permanente și utilizează, de
        exemplu, tehnologia JavaScript sau Flash.
      </p>,
      <p>
        Datorită cookie-urilor folosite de aplicația noastră web, aceasta poate fi folosită pentru a
        beneficia de serviciile dorite. Fișierele cookie din aplicația DACIAN TECHNOLOGIES S.R.L. ne
        arată ce te interesează pe tine şi pe alţi vizitatori, ceea ce ne ajută să îmbunătățim
        utilizarea acesteia pentru toată lumea.
      </p>,
      <div>
        <p>Cookie-urile folosite de aplicația noastră sunt următoarele:</p>
        <ul className="list-disc pl-16">
          <li>
            <strong>Strict necesare</strong>. Acestea sunt necesare pentru a asigura
            funcționalitatea eficientă din punct de vedere tehnic al acesti aplicații web.
          </li>
          <li>
            <strong>Cookie-uri funcționale</strong>. Acestea ajută la îmbunătățirea experienței
            memorând alegerile tale.
          </li>
        </ul>
      </div>,
      <p>
        Cookie-urile stocate pe calculatorul tău pot fi accesate atunci când vizitezi aplicația web
        sau deschizi un email pe care ţi-l trimitem. Dacă doreşti să ştergi cookie-uri care sunt
        deja în calculatorul tău, consultă instrucţiunile browserului tău apăsând pe “Help” în
        același meniu al browser-ului.
      </p>,
      <p>
        DACIAN TECHNOLOGIES S.R.L. prelucrează cookie-uri numai cu acordul tău. Pentru a retrage
        acordul sau a fi de acord cu utilizarea cookie-urilor, efectuează setările adecvate în
        browser-ul tău. Cu toate acestea, unele din caracteristicile aplicației nu pot funcţiona
        fără cookie-uri. Cerând acest acord, vom prezenta scopul pentru care acest tip de informaţii
        despre tine vor fi prelucrate şi vă vom informa despre drepturile voastre. Poţi afla mai
        multe informaţii despre cookie-uri{' '}
        <a
          className="underline"
          href="https://ro.wikipedia.org/wiki/Cookie"
          target="_blank"
          rel="noreferrer"
        >
          aici
        </a>
        .
      </p>,
    ],
  },
  {
    title: 'Cum prevenim cookie-urile?',
    content: [
      <p>
        Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste
        setări se găsesc de regulă in &quot;opțiuni&quot; sau în meniul de &quot;preferințe&quot; al
        browser-ului.
      </p>,
      <div>
        <p>
          Pentru a înțelege aceste setări, următoarle link-uri pot fi folositoare, altfel puteți
          folosi opțiunea &quot;ajutor&quot; a browser-ului pentru mai multe detalii.
        </p>
        <ul className="list-disc pl-16">
          <li>
            <a
              className="underline"
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              Setări cookie-uri în Safari
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              target="_blank"
              rel="noreferrer"
            >
              Setări cookie-uri în Edge
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
              target="_blank"
              rel="noreferrer"
            >
              Setări cookie-uri în Firefox
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.google.com/chrome/answer/95647?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Setări cookie-uri în Chrome
            </a>
          </li>
        </ul>
      </div>,
      <p>
        Pentru setările cookie-urilor generate de terți, puteți consulta următorul link:{' '}
        <a
          className="underline"
          href="http://www.youronlinechoices.com/ro/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.youronlinechoices.com/ro/
        </a>
        .
      </p>,
    ],
  },
];

const enParagraphs = [
  {
    title: 'General information',
    content: [
      <p>
        In order to maintain a good functionality of the web application, DACIAN TECHNOLOGIES S.R.L.
        uses the technology called &quot;cookie&quot;. Cookies are small files sent to your computer
        which can be accessed later. These can be temporary or permanent and for example it&apos;s
        using JavaScript or Flash technology.
      </p>,
      <p>
        You can benefit of desired services due to the cookies used by our web application. The
        cookies within <storng>DACIAN TECHNOLOGIES S.R.L.</storng> web application can show us
        what&apos;s interesting for you and for others visitors, therefore we can improve
        everyone&apos;s experience.
      </p>,
      <div>
        <p>We&apos;re using the following types of cookies:</p>
        <ul className="list-disc pl-16">
          <li>
            <strong>Strictly needed</strong>. This type of cookies is needed to assure an efficient
            functionality of this application from a technical point of view.
          </li>
          <li>
            <strong>Functional cookies</strong>. This type of cookies helps your experience by
            remembering your choices.
          </li>
        </ul>
      </div>,
      <p>
        Stored cookies from your computer can be accessed when you visit the application or open an
        e-mail from us. If you wish to delete already stored cookies from your computer you should
        check the browser instructions by using the “Help” menu of the browser.
      </p>,
      <p>
        DACIAN TECHNOLOGIES S.R.L. uses cookies only with your permission. In order to not consent
        or agree with our policy please do the right settings within your browser. However, some of
        the features of this application cannot be used without cookies. By requesting your
        approval, we&apos;ll present why we process this information about you and we&apos;ll inform
        you about your rights. More information about cookies can be found{' '}
        <a className="underline" href="https://en.wikipedia.org/wiki/HTTP_cookie" rel="noreferrer">
          here
        </a>
        .
      </p>,
    ],
  },
  {
    title: 'How to prevent cookies?',
    content: [
      <p>
        All modern browsers offers a way to change cookies settings. These settings are usually
        found in &quot;options&quot; or &quot;preferences&quot; menu of the browser.
      </p>,
      <div>
        <p>
          In order to understand this settings you can either use one of the following links or you
          can use the &quot;help&quot; menu of your browser.
        </p>
        <ul className="list-disc pl-16">
          <li>
            <a
              className="underline"
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              Safari cookies settings
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              target="_blank"
              rel="noreferrer"
            >
              Edge cookies settings
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
              target="_blank"
              rel="noreferrer"
            >
              Firefox cookies settings
            </a>
          </li>
          <li>
            <a
              className="underline"
              href="https://support.google.com/chrome/answer/95647?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Chrome cookies settings
            </a>
          </li>
        </ul>
      </div>,
      <p>
        For 3<sup>rd</sup> party generated cookies you can check the following link:{' '}
        <a
          className="underline"
          href="http://www.youronlinechoices.com/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.youronlinechoices.com/
        </a>
      </p>,
    ],
  },
];

const Cookies = () => {
  const { isRo } = useContext(AppContext);

  return (
    <div className="flex flex-col gap-y-8">
      <h1 className="text-4xl text-primary font-medium">
        {isRo ? 'Politică de Cookies' : 'Cookies Policy'}
      </h1>
      {(isRo ? roParagraphs : enParagraphs).map(({ title, content }, index) => (
        <div key={index} className="flex flex-col gap-y-2">
          <h2 className="text-3xl text-black font-medium">{title}</h2>
          {content.map((text, contentIndex) => (
            <div key={contentIndex}>{text}</div>
          ))}
        </div>
      ))}
      <hr />
      <p className="text-right italic">
        {isRo ? 'Ultima actualizare: 01-09-2022' : 'Last update: 2022-09-01'}
      </p>
    </div>
  );
};

export default Cookies;
