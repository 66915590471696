import React from 'react';
import PropTypes from 'prop-types';
import HeroButton from '../../ui/Buttons/HeroButton';

const BrandDetails = (props) => {
  const { isRo, onAction } = props;

  return (
    <div className="flex flex-col gap-32 justify-center">
      <div className="flex flex-col gap-4">
        <p className="text-white tracking-widest uppercase w-full text-lg lg:text-2xl text-center font-thin">
          {isRo ? 'Dezvoltare Software' : 'Software Development'}
        </p>
        <h1 className="text-white font-fancy w-full md:w-auto text-7xl lg:text-hero font-black leading-tight text-center uppercase">
          {isRo ? 'Dă viață proiectului!' : "Let's make it happen!"}
        </h1>
        <h2 className="w-3/4 md:w-auto text-white text-xl lg:text-3xl mx-auto text-center font-light">
          {isRo
            ? 'Ne folosim de experiența noastră pentru a ajuta clienții în dezvoltarea și implementarea soluțiilor software.'
            : 'We use our expertise to help clients to design and build their software solutions.'}
        </h2>
      </div>
      <div className="text-center">
        <HeroButton onClick={onAction}>
          {isRo ? 'Să ne cunoaștem' : 'Tell us about yourself'}
        </HeroButton>
      </div>
    </div>
  );
};

BrandDetails.propTypes = {
  isRo: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default BrandDetails;
