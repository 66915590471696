import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Client from 'components/Client';
import Home from '../components/Home';
import Error404 from '../components/ui/Error404';
import POLICIES_ROUTES from './policies';
import Login from '../components/Login';
import Dashboard from '../components/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import AppContext from '../contexts/AppContext';

const AuthenticationRoutes = () => [
  <Route key={1} exact path="/login" component={Login} />,
  <ProtectedRoute key={2} exact path="/admin">
    <Dashboard />
  </ProtectedRoute>,
];

const ROUTES = () => {
  const {
    featureFlags: { isAuthenticationEnabled = false },
  } = useContext(AppContext);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/ro" component={Home} />
      <Route exact path="/clients/:slug" component={Client} />
      {isAuthenticationEnabled && <AuthenticationRoutes />}
      {POLICIES_ROUTES}
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default ROUTES;
