import {
  EN_COOKIES_POLICY_LINK,
  EN_PRIVACY_POLICY_LINK,
  RO_COOKIES_POLICY_LINK,
  RO_PRIVACY_POLICY_LINK,
} from '../constants/links';

export const getCookie = (cookies, name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = (cookies || '').split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getPoliciesLinks = (isRo) =>
  isRo
    ? {
        privacyPolicyLink: RO_PRIVACY_POLICY_LINK,
        cookiesPolicyLink: RO_COOKIES_POLICY_LINK,
      }
    : {
        privacyPolicyLink: EN_PRIVACY_POLICY_LINK,
        cookiesPolicyLink: EN_COOKIES_POLICY_LINK,
      };

export const clearAuthCookies = () => {
  setCookie('access_token', null, -1);
  setCookie('refresh_token', null, -1);
};

export const onFieldChange = (value, validate, setter) =>
  setter({
    isDirty: true,
    value,
    isValid: validate(value),
  });
