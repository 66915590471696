import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import setSeoData from '../utility/seo';
import AppContext from '../contexts/AppContext';
import APPLICATION_ROUTES from '../routes';
import TermsAndConditionsAgreement from './policies/TermsAndConditionsAgreement';
import Layout from './Layout';
import FEATURE_FLAGS from '../constants/featureFlags';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const shouldUseGradient = (pathname) => pathname === '/' || pathname === '/ro';

const App = (props) => {
  const { agreedConsent, isRo: defaultIsRo, authenticated } = props;
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated);
  const [isAgreedConsent, setIsAgreeConsent] = useState(agreedConsent);
  const [isRo, setIsRo] = useState(defaultIsRo);
  const [isGradient, setIsGradient] = useState(shouldUseGradient(history.location.pathname));

  useEffect(() => {
    history.listen((newLocation) => {
      const { pathname } = newLocation;
      setIsRo(pathname.startsWith('/ro'));

      setSeoData(pathname);
      setIsGradient(shouldUseGradient(pathname));
    });

    setSeoData(history.location.pathname);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <AppContext.Provider
        value={{
          isRo,
          isAgreedConsent: Boolean(isAgreedConsent),
          isAuthenticated,
          setIsAuthenticated,
          isGradient,
          featureFlags: FEATURE_FLAGS,
        }}
      >
        <Layout isGradient={isGradient}>
          <APPLICATION_ROUTES />
        </Layout>
        {!isAgreedConsent && <TermsAndConditionsAgreement onChange={setIsAgreeConsent} />}
      </AppContext.Provider>
    </>
  );
};

App.propTypes = {
  agreedConsent: PropTypes.bool,
  isRo: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool,
};

App.defaultProps = {
  agreedConsent: false,
  authenticated: false,
};

export default App;
