import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import pick from 'lodash/pick';
import { toast } from 'react-toastify';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { EN_CONTACT_LINK, RO_CONTACT_LINK } from 'constants/links';
import AppContext from '../../contexts/AppContext';
import SectionWithTitle from '../Layout/SectionWithTitle';
import { Input, Textarea } from '../ui/forms/TextField';
import ContainedButton from '../ui/Buttons/ContainedButton';
import LoadingSpinner from '../ui/LoadingSpinner';
import { isRequired, isValidEmail } from '../../utility/validation';
import { onFieldChange } from '../../utility/utils';

const { EMAILS_SERVICE_ENDPOINT } = process.env;

const GoogleMap = () => (
  <div className="w-full bg-gray-100 rounded-lg">
    <iframe
      className="rounded-lg"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2854.451785641465!2d23.81945821744384!3d44.32121950000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752d705a46062a3%3A0x473714263aebe5af!2sDacianTech!5e0!3m2!1sen!2sro!4v1661350925429!5m2!1sen!2sro"
      width="100%"
      height="100%"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  </div>
);

const Contact = () => {
  const { isRo } = useContext(AppContext);
  const [nameField, setNameField] = useState(EMPTY_DEFAULT_FIELD);
  const [emailField, setEmailField] = useState(EMPTY_DEFAULT_FIELD);
  const [domainField, setDomainField] = useState(EMPTY_DEFAULT_FIELD);
  const [countryField, setCountryField] = useState(EMPTY_DEFAULT_FIELD);
  const [messageField, setMessageField] = useState(EMPTY_DEFAULT_FIELD);
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { text: title, hash } = pick(isRo ? RO_CONTACT_LINK : EN_CONTACT_LINK, ['text', 'hash']);
  const isFormValid = ![nameField, emailField, domainField, countryField, messageField].some(
    (o) => o.isValid === false,
  );
  const canSubmit = isFormValid && isTCAccepted && !isSubmitting;

  const onNameChange = (event) => onFieldChange(event.target.value, isRequired, setNameField);
  const onEmailChange = (event) => onFieldChange(event.target.value, isValidEmail, setEmailField);
  const onDomainChange = (event) => onFieldChange(event.target.value, isRequired, setDomainField);
  const onCountryChange = (event) => onFieldChange(event.target.value, isRequired, setCountryField);
  const onMessageChange = (event) => onFieldChange(event.target.value, isRequired, setMessageField);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isFormValid) {
      setIsSubmitting(true);

      const response = await fetch(`${EMAILS_SERVICE_ENDPOINT}/contact`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: nameField.value,
          email: emailField.value,
          domain: domainField.value,
          country: countryField.value,
          message: messageField.value,
          accepted_terms_and_conditions: isTCAccepted,
        }),
      });

      if (response.status === 200) {
        setNameField(EMPTY_DEFAULT_FIELD);
        setEmailField(EMPTY_DEFAULT_FIELD);
        setDomainField(EMPTY_DEFAULT_FIELD);
        setCountryField(EMPTY_DEFAULT_FIELD);
        setMessageField(EMPTY_DEFAULT_FIELD);
        setIsTCAccepted(false);

        toast.success(
          isRo ? 'Mesajul a fost transmis cu succes.' : 'The message was sent successfully.',
        );
      } else {
        toast.error(
          isRo
            ? 'Ceva nu a funcționat cum trebuie. Vă rugăm încercați mai tărziu.'
            : 'Something went wrong. Please try again later.',
        );
      }

      setIsSubmitting(false);
    }
  };

  return (
    <SectionWithTitle id={hash.slice(1)} title={title}>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-16">
        <form className="flex flex-col gap-8" onSubmit={onSubmit} noValidate>
          <Input
            id="name"
            name="name"
            label={isRo ? 'Nume' : 'Name'}
            value={nameField.value}
            onChange={onNameChange}
            required
            isDirty={nameField.isDirty}
            isValid={nameField.isValid}
          />
          <Input
            id="email"
            name="email"
            label={isRo ? 'Adresă de e-mail' : 'E-mail address'}
            value={emailField.value}
            onChange={onEmailChange}
            required
            isDirty={emailField.isDirty}
            isValid={emailField.isValid}
          />
          <Input
            id="business"
            name="business"
            label={isRo ? 'Domeniu de activitate' : 'Business domain'}
            value={domainField.value}
            onChange={onDomainChange}
            required
            isDirty={domainField.isDirty}
            isValid={domainField.isValid}
          />
          <Input
            id="country"
            name="country"
            label={isRo ? 'Țară' : 'Country'}
            value={countryField.value}
            onChange={onCountryChange}
            required
            isDirty={countryField.isDirty}
            isValid={countryField.isValid}
          />
          <Textarea
            id="message"
            name="message"
            label={isRo ? 'Cu ce te putem ajuta?' : 'How can we help you?'}
            value={messageField.value}
            onChange={onMessageChange}
            required
            isDirty={messageField.isDirty}
            isValid={messageField.isValid}
          />
          <div className="flex flex-col gap-8 items-center">
            <div className="w-full flex mb-4 gap-2">
              <input
                onChange={(event) => setIsTCAccepted(event.target.checked)}
                id="tc-agreement"
                type="checkbox"
                checked={isTCAccepted}
                className="w-8 h-8 text-red-600 bg-gray-100 rounded border-gray-300 focus:ring-white focus:ring-2 mt-0.2"
              />
              <label htmlFor="tc-agreement" className="text-white">
                {isRo ? (
                  <p>
                    Sunt de accord cu{' '}
                    <Link to="/ro/politica-de-confidentialitate" className="underline">
                      Politica de Confidențialitate
                    </Link>
                    .
                  </p>
                ) : (
                  <p>
                    I agree with{' '}
                    <Link to="/privacy-policy" className="underline">
                      Privacy Policy
                    </Link>
                    .
                  </p>
                )}
              </label>
            </div>
            <div className="w-full flex gap-8 justify-center">
              {isSubmitting ? (
                <LoadingSpinner variant="secondary" />
              ) : (
                <ContainedButton isSecondary fullWidth type="submit" disabled={!canSubmit}>
                  {isRo ? 'Să ne cunoaștem!' : "Let's connect!"}
                </ContainedButton>
              )}
            </div>
          </div>
        </form>
        <div className="hidden xl:flex">
          <GoogleMap />
        </div>
      </div>
    </SectionWithTitle>
  );
};

export default Contact;
