export const ROMANIAN = {
  home: {
    title: 'Dă viață soluției perfecte pentru software-ul tău | DacianTech',
    description:
      'Ne folosim de experiența noastră pentru a ajuta clienții în dezvoltarea și implementarea soluțiilor software.',
  },
  privacy: {
    title: 'Politică de confidențialitate | DacianTech',
    description:
      'Ținem foarte mult la datele personale ale utilizatorilor noștri. Vă rugăm citiți cu atenție politica noastră de confidențialitate.',
  },
  cookies: {
    title: 'Politică de cookies | DacianTech',
    description:
      'Ne dorim ca utilizatorii noștri să fie informați în legătură cu cookies-urile: ce sunt și pentru ce le folosim.',
  },
};

export const ENGLISH = {
  home: {
    title: "Let's design and build your perfect software solution | DacianTech'",
    description:
      'We use our expertise to help clients to design and build their software solutions.',
  },
  privacy: {
    title: 'Privacy policy | DacianTech',
    description:
      'We care about our users personal information. Please read carefully our privacy policy.',
  },
  cookies: {
    title: 'Cookies policy | DacianTech',
    description:
      'We want our users to be informed about cookies: what they are and how we are using them.',
  },
};
