import { ENGLISH, ROMANIAN } from '../constants/seo';

export const getSeoData = (pathname) => {
  if (pathname === '/') {
    return ENGLISH.home;
  }

  if (pathname === '/privacy-policy') {
    return ENGLISH.privacy;
  }

  if (pathname === '/cookies-policy') {
    return ENGLISH.cookies;
  }

  if (pathname === '/ro') {
    return ROMANIAN.home;
  }

  if (pathname === '/ro/politica-de-confidentialitate') {
    return ROMANIAN.privacy;
  }

  if (pathname === '/ro/politica-de-cookies') {
    return ROMANIAN.cookies;
  }

  return {};
};

const setSeoData = (pathname) => {
  const { title = '', description = '' } = getSeoData(pathname);
  let metaAttribute;

  try {
    metaAttribute = document.head.children.namedItem('description');
  } catch {
    metaAttribute = null;
  }

  document.title = title;
  if (metaAttribute) {
    metaAttribute.content = description;
  }
};

export default setSeoData;
