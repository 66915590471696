import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { getCookie } from '../utility/utils';
import App from './App';

const AppWrapper = (props) => {
  const { apolloClient } = props;
  const isRo = window.location.pathname.startsWith('/ro');

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App
          isRo={isRo}
          agreedConsent={!!getCookie(document.cookie, 'agreed_consent')}
          authenticated={!!getCookie(document.cookie, 'access_token')}
        />
      </BrowserRouter>
    </ApolloProvider>
  );
};

AppWrapper.propTypes = {
  apolloClient: PropTypes.object.isRequired,
};

export default AppWrapper;
