import React, { useContext } from 'react';
import pick from 'lodash/pick';
import AppContext from '../../contexts/AppContext';
import Card from '../ui/Card';
import SectionWithTitle from '../Layout/SectionWithTitle';
import { EN_SERVICES_LINK, RO_SERVICES_LINK } from '../../constants/links';

const Services = () => {
  const { isRo } = useContext(AppContext);
  const { text: title, hash } = pick(isRo ? RO_SERVICES_LINK : EN_SERVICES_LINK, ['text', 'hash']);
  const currentYear = new Date().getFullYear();
  const experienceYears = currentYear - 2015;

  return (
    <SectionWithTitle isColorTheme id={hash.slice(1)} title={title}>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 md:gap-32">
        <Card isWhiteTheme title={isRo ? 'Aplicații web' : 'Web apps'}>
          {isRo ? (
            <>
              Implică-ne în aplicația web pe care o ai deja sau hai să pornim una de la 0.
              Indiferent de soluția optimă pentru tine, echipa noastră este aici să te ajute cu
              vastele cunoștiințe în folosirea diferitelor tehnologii și limbaje de programare.
            </>
          ) : (
            <>
              Imply us in your existing web application or let’s get together to set up a new one.
              Whatever works better for you, our team members will help you with their extensive
              knowledge in working with different technologies and programming languages.
            </>
          )}
        </Card>
        <Card isWhiteTheme title={isRo ? 'Magazine online' : 'E-commerce web apps'}>
          {isRo ? (
            <>
              Azi mai mult ca oricând tot mai multe afaceri își vând produsele și serviciile în
              mediul online. Echipa noastră te poate ajuta cu dezvoltarea unui mediu online unde să
              fii în contact cu clienții și cu ajutorul căreia îți vei mări numărul de clienți.
            </>
          ) : (
            <>
              Nowadays most of the business are trading their products or services online. Our team
              can help you in setting up an online environment where you can be in contact with your
              clients and where you can reach out to a larger base of people.
            </>
          )}
        </Card>
        <Card isWhiteTheme title={isRo ? 'Site-uri de prezentare' : 'Simple websites'}>
          {isRo ? (
            <>
              Avem peste {experienceYears} ani de experiență în dezvoltarea site-urilor web. Hai să
              discutăm despre nevoile tale și împreună vom alegea cea mai bună soluție pentru tine.
            </>
          ) : (
            <>
              We have over {experienceYears} years of experience in web development. No matter what
              the needs of your website are, let us know your ideas and let’s see how we can help
              you.
            </>
          )}
        </Card>
      </div>
    </SectionWithTitle>
  );
};

export default Services;
