import React from 'react';
import { Route } from 'react-router-dom';
import Privacy from '../components/policies/Privacy';
import Cookies from '../components/policies/Cookies';

export default (
  <>
    <Route exact path="/privacy-policy" component={Privacy} />
    <Route exact path="/ro/politica-de-confidentialitate" component={Privacy} />
    <Route exact path="/cookies-policy" component={Cookies} />
    <Route exact path="/ro/politica-de-cookies" component={Cookies} />
  </>
);
