import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import pick from 'lodash/pick';
import CLIENTS from 'constants/clients';
import { EN_CLIENTS_LINK, RO_CLIENTS_LINK } from '../../constants/links';
import AppContext from '../../contexts/AppContext';
import SectionWithTitle from '../Layout/SectionWithTitle';

const Clients = () => {
  const { isRo } = useContext(AppContext);

  const { text: title, hash } = pick(isRo ? RO_CLIENTS_LINK : EN_CLIENTS_LINK, ['text', 'hash']);
  return (
    <SectionWithTitle id={hash.slice(1)} className="bg-gray-100" title={title}>
      <div className="flex flex-col justify-around items-center gap-16 md:gap-32 md:flex-row">
        {CLIENTS.map(({ id, src, alt, slug }) => (
          <div key={id} className="flex items-center justify-center">
            <Link to={`clients/${slug}`}>
              <img className="hover:scale-105" src={src} alt={alt} height={200} />
            </Link>
          </div>
        ))}
      </div>
    </SectionWithTitle>
  );
};

export default Clients;
