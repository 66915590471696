import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LINKS } from '../../constants/links';
import AppContext from '../../contexts/AppContext';
import { clearAuthCookies } from '../../utility/utils';

const Navigation = (props) => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    featureFlags: { isAuthenticationEnabled },
  } = useContext(AppContext);
  const { isHomepage } = props;
  const { isRo } = useContext(AppContext);
  const location = useLocation();
  const [isMobileMenuOpened, _setIsMobileMenuOpened] = useState(false);
  const isMobileMenuOpenedRef = useRef(isMobileMenuOpened);
  const headerRef = useRef(null);
  const logoRef = useRef(null);
  const navListRef = useRef(null);
  const hamburgerRef = useRef(null);
  const LINKS_BY_LANGUAGE = isRo ? LINKS.ro : LINKS.en;

  useEffect(() => {
    if (location.hash) {
      const { top: elementTop } = document.querySelector(location.hash).getBoundingClientRect();
      window.scrollTo(0, elementTop + window.scrollY - 78);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  const setIsMobileMenuOpened = (data) => {
    isMobileMenuOpenedRef.current = data;
    _setIsMobileMenuOpened(data);
  };

  const onLogout = () => {
    clearAuthCookies();
    setIsAuthenticated(false);
  };

  const onLinkClick = () => {
    setIsMobileMenuOpened(false);
  };

  const authLink = {
    id: LINKS_BY_LANGUAGE.length + 1,
  };
  if (isAuthenticated) {
    authLink.pathname = '/';
    authLink.text = 'Logout';
  } else {
    authLink.pathname = '/login';
    authLink.text = 'Login';
  }

  const allLinks = useMemo(() => {
    const links = [...LINKS_BY_LANGUAGE];
    if (isAuthenticationEnabled) {
      links.push(authLink);
    }

    return links;
  }, [LINKS_BY_LANGUAGE, authLink]);

  const setPrimaryHeader = () => {
    headerRef.current.classList.add('bg-primary');
    headerRef.current.classList.add('shadow');
  };

  const setTransparentHeader = () => {
    headerRef.current.classList.remove('bg-primary');
    headerRef.current.classList.remove('shadow');
  };

  const onHamburgerClick = () => {
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  const onDocumentClick = useCallback((e) => {
    const target = (e && e.target) || (e && e.srcElement);

    if (!headerRef.current.contains(target)) {
      setIsMobileMenuOpened(false);
    }
  }, []);

  useEffect(() => {
    if (isMobileMenuOpened) {
      navListRef.current.classList.remove('hidden');
      document.addEventListener('click', onDocumentClick);
    } else {
      navListRef.current.classList.add('hidden');
      document.removeEventListener('click', onDocumentClick);
    }
  }, [isMobileMenuOpened]);

  useEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY;
      const isCurrentPathHomepage = ['/', '/ro'].includes(window.location.pathname);

      if (!isMobileMenuOpenedRef.current && isCurrentPathHomepage) {
        if (scrollPos > 10) {
          setPrimaryHeader();
        } else {
          setTransparentHeader();
        }
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (isHomepage) {
      const scrollPos = window.scrollY;

      if (isMobileMenuOpened) {
        if (scrollPos <= 10) {
          setPrimaryHeader();
        }
      } else if (scrollPos <= 10) {
        setTransparentHeader();
      }
    }
  }, [isHomepage, isMobileMenuOpened]);

  return (
    <nav
      ref={headerRef}
      className={cn(
        'fixed h-navigation flex items-center w-full z-30 top-0 bg-primary text-white shadow',
      )}
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-8">
        <div className="flex items-center">
          <Link
            className={cn(
              'toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl',
              {
                'text-white': isHomepage,
                'text-gray-800': !isHomepage,
              },
            )}
            to={isRo ? '/ro' : '/'}
          >
            <img
              ref={logoRef}
              src="images/dct-logo-white.png"
              width={200}
              alt="DacianTech horizontal Logo white"
            />
          </Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            ref={hamburgerRef}
            type="button"
            className={cn(
              'bg-transparent flex items-center justify-center p-1 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out text-white hover:text-white-darker',
            )}
            onClick={onHamburgerClick}
          >
            <i className="fa-solid fa-bars text-4xl text-white" />
          </button>
        </div>
        <div
          ref={navListRef}
          className="fixed top-navigation lg:top-0 right-0 left-0 w-full flex-grow lg:relative lg:flex lg:items-center lg:w-auto hidden mt-0 lg:mt-0 bg-primary lg:bg-transparent text-white p-4 lg:p-0 z-20 shadow-lg lg:shadow-none"
        >
          <ul className="flex flex-col lg:flex-row justify-end flex-1 lg:items-center uppercase gap-4 lg:gap-2">
            {allLinks.map(({ id, text, pathname, hash }) => (
              <li key={id} className="text-center">
                <Link
                  className={cn(
                    'text-sm font-fancy inline-block py-2 px-0 lg:px-4 no-underline text-white hover:text-white-darker',
                  )}
                  to={{
                    pathname,
                    hash,
                  }}
                  onClick={text === 'Logout' ? onLogout : onLinkClick}
                >
                  {text}
                </Link>
              </li>
            ))}
            <li>
              <div className="flex gap-4 lg:gap-0 items-center justify-center">
                <Link
                  className={cn(
                    'text-sm font-fancy inline-block py-2 px-0 lg:px-2 no-underline text-white hover:text-white-darker',
                  )}
                  to="/"
                  onClick={onLinkClick}
                >
                  EN
                </Link>
                <span className={cn('text-sm font-fancy inline-block text-white')}>/</span>
                <Link
                  className={cn(
                    'text-sm font-fancy inline-block py-2 px-0 lg:px-2 no-underline text-white hover:text-white-darker',
                  )}
                  to="/ro"
                  onClick={onLinkClick}
                >
                  RO
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  isHomepage: PropTypes.bool.isRequired,
};

export default Navigation;
