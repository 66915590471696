import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';
import ContainedButton from '../ui/Buttons/ContainedButton';
import { getPoliciesLinks } from '../../utility/utils';

const TermsAndConditionsAgreement = (props) => {
  const { onChange } = props;
  const { isRo } = useContext(AppContext);
  const content = useMemo(() => {
    const { privacyPolicyLink, cookiesPolicyLink } = getPoliciesLinks(isRo);
    const { text: privacyPolicyText, link: privacyPolicyRoute } = privacyPolicyLink;
    const { text: cookiesPolicyText, link: cookiesPolicyRoute } = cookiesPolicyLink;

    const privacyPolicyLinkContent = (
      <Link className="text-black underline" to={privacyPolicyRoute}>
        {privacyPolicyText}
      </Link>
    );

    const cookiesPolicyLinkContent = (
      <Link className="text-black underline" to={cookiesPolicyRoute}>
        {cookiesPolicyText}
      </Link>
    );

    if (isRo) {
      return (
        <>
          Vă rugăm să luați la cunoștiință {privacyPolicyLinkContent} și {cookiesPolicyLinkContent}.
        </>
      );
    }

    return (
      <>
        Please take note of our {privacyPolicyLinkContent} and our {cookiesPolicyLinkContent}.
      </>
    );
  }, [isRo]);

  const agreeTermsAndConditions = () => {
    onChange(true);
    const expires_at = new Date();
    expires_at.setMonth(expires_at.getMonth() + 1);
    document.cookie = `agreed_consent=True; expires=${expires_at.toString()}; path=/; domain=.dacian.tech`;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 py-4 bg-secondary z-50">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-center gap-y-4 lg:gap-x-8 w-full">
        <p className="text-black text-center">{content}</p>
        <ContainedButton onClick={agreeTermsAndConditions} size="small">
          Accept
        </ContainedButton>
      </div>
    </div>
  );
};

TermsAndConditionsAgreement.propTypes = {
  onChange: PropTypes.func.isRequired,
};

TermsAndConditionsAgreement.displayName = 'TermsAndConditionsAgreement';

export default TermsAndConditionsAgreement;
