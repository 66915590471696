import React from 'react';
import Hero from './Hero';
import About from './About';
import Clients from './Clients';
import Services from './Services';
import Skills from './Skills';
import Contact from './Contact';

const Home = () => (
  <>
    <Hero />
    <About />
    <Services />
    <Clients />
    <Skills />
    <Contact />
  </>
);

export default Home;
