import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { childrenPropTypes } from '../../utility/propTypes';

const SectionWithTitle = (props) => {
  const { id, title, isColorTheme, children } = props;

  return (
    <section className={cn({ 'bg-white': !isColorTheme, relative: isColorTheme })}>
      {isColorTheme && (
        <div
          className="-z-10 absolute top-0 right-0 bottom-0 left-0 bg-cover bg-center"
          style={{ backgroundImage: 'url(images/cogs-bg-overalay.png)' }}
        >
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-primary/[.95]" />
        </div>
      )}
      <div id={id} className="container mx-auto flex flex-col gap-16 md:gap-32 py-16 md:py-32">
        <div className="flex flex-col gap-8">
          <h3
            className={cn(
              'font-fancy uppercase font-black w-full text-7xl font-bold leading-tight text-center',
              {
                'text-white': isColorTheme,
              },
            )}
          >
            {title}
          </h3>
          <div className="w-full">
            <div className="h-1.5 mx-auto w-96 my-0 py-0 rounded-t bg-gradient-to-r from-secondary-dark to-white" />
          </div>
        </div>
        {children}
      </div>
    </section>
  );
};

SectionWithTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  isColorTheme: PropTypes.bool,
  ...childrenPropTypes,
};

SectionWithTitle.defaultProps = {
  id: null,
  isColorTheme: false,
};

SectionWithTitle.displayName = 'SectionWithTitle';

export default SectionWithTitle;
