import React from 'react';
import PropTypes from 'prop-types';

const Experience = (props) => {
  const { description } = props;
  return <p className="text-base">{description}</p>;
};

Experience.propTypes = {
  description: PropTypes.string.isRequired,
};

Experience.displayName = 'Experience';

export default Experience;
