import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { name, link, src, alt } = props;
  return (
    <div className="flex flex-col gap-2 md:gap-4 items-center md:flex-row">
      <div className="flex w-96">
        <a className="hover:scale-105" href={link.url} target="_blank" rel="noreferrer">
          <img className="w-full" src={src} alt={alt} />
        </a>
      </div>
      <div className="flex flex-col gap-2 items-center md:items-start">
        <h1 className="text-5xl md:text-9xl font-bold leading-none">{name}</h1>
        <a
          className="font-fancy text-gray-500 hover:text-gray-700 text-3xl md:text-4xl leading-none"
          href={link.url}
          target="_blank"
          rel="noreferrer"
        >
          {link.text}
        </a>
      </div>
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Header.displayName = 'Header';

export default Header;
