import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import pick from 'lodash/pick';
import AppContext from '../../../contexts/AppContext';
import { EN_CONTACT_LINK, RO_CONTACT_LINK } from '../../../constants/links';
import BrandDetails from './BrandDetails';

const Hero = () => {
  const { isRo } = useContext(AppContext);
  const history = useHistory();

  const onAction = () =>
    history.push(pick(isRo ? RO_CONTACT_LINK : EN_CONTACT_LINK, ['pathname', 'hash']));

  return (
    <>
      <div
        className="absolute top-0 right-0 bottom-0 left-0 overflow-hidden shadow-lg bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: 'url(images/cogs-bg-overalay.png)' }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-primary/[.95]" />
      </div>
      <div className="flex items-center justify-center h-screen w-screen z-10">
        <BrandDetails isRo={isRo} onAction={onAction} />
      </div>
    </>
  );
};

export default Hero;
