import React, { useContext, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { EMPTY_DEFAULT_FIELD } from 'constants/forms';
import { toast } from 'react-toastify';
import axios from '../utility/axios';
import { isRequired, isValidEmail } from '../utility/validation';
import AppContext from '../contexts/AppContext';
import { Input } from './ui/forms/TextField';
import ContainedButton from './ui/Buttons/ContainedButton';
import { onFieldChange } from '../utility/utils';

const Login = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AppContext);
  const [emailField, setEmailField] = useState(EMPTY_DEFAULT_FIELD);
  const [passwordField, setPasswordField] = useState(EMPTY_DEFAULT_FIELD);
  const history = useHistory();
  const location = useLocation();
  const canSubmit = ![emailField, passwordField].some((o) => !o.isValid);

  const clearFields = (value) => {
    setEmailField(value);
    setPasswordField(value);
  };

  const onEmailChange = (event) => onFieldChange(event.target.value, isValidEmail, setEmailField);
  const onPasswordChange = (event) =>
    onFieldChange(event.target.value, isRequired, setPasswordField);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!canSubmit) {
      return;
    }

    const formData = new FormData();
    formData.append('username', emailField.value);
    formData.append('password', passwordField.value);

    let response;

    try {
      response = await axios.post('/login/', formData);
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          toast.error('Unauthorized! Invalid username or password');
        } else {
          toast.error('Something went wrong! Please try again later');
        }
      } else {
        toast.error('Network error occurred. Please check your internet connection and try again.');
      }
    }

    clearFields(EMPTY_DEFAULT_FIELD);
    if (response && response.status === 200) {
      setIsAuthenticated(true);

      const returnUrl = location.state?.from?.pathname || '/admin';
      history.push(returnUrl);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="flex justify-center items-center mt-16">
      <form
        className="flex flex-col w-160 gap-8 items-stretch p-16 gradient shadow-2xl rounded-lg"
        onSubmit={handleSubmit}
        noValidate
      >
        <Input
          className="placeholder-gray-500"
          variant="dark"
          id="email"
          name="email"
          value={emailField.value}
          onChange={onEmailChange}
          required
          isDirty={emailField.isDirty}
          isValid={emailField.isValid}
          autoComplete="username"
          placeholder="email"
          autoFocus
        />
        <Input
          className="placeholder-gray-500"
          variant="dark"
          id="password"
          name="password"
          type="password"
          value={passwordField.value}
          onChange={onPasswordChange}
          required
          isDirty={passwordField.isDirty}
          isValid={passwordField.isValid}
          autoComplete="current-password"
          placeholder="password"
        />
        <div>
          <ContainedButton type="submit" isSecondary fullWidth disabled={!canSubmit}>
            LOGIN
          </ContainedButton>
        </div>
      </form>
    </div>
  );
};
Login.displayName = 'Login';

export default Login;
