import React from 'react';
import PropTypes from 'prop-types';

const SolutionsList = (props) => {
  const { solutions } = props;

  return (
    <ul className="flex flex-col gap-4 list-disc list-inside pl-8">
      {solutions.map((solution, index) => (
        <li key={index} className="text-base">
          {solution}
        </li>
      ))}
    </ul>
  );
};

SolutionsList.propTypes = {
  solutions: PropTypes.array.isRequired,
};

SolutionsList.displayName = 'SolutionsList';

export default SolutionsList;
