import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CLIENTS from 'constants/clients';
import Error404 from 'components/ui/Error404';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Chip from 'components/ui/Chip';
import Header from './Header';
import Role from './Role';
import Experience from './Experience';
import SolutionsList from './SolutionsList';

const Client = () => {
  const { slug } = useParams();
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const matchingClient = CLIENTS.find((c) => c.slug === slug);
    setClient(matchingClient);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 items-center">
        <LoadingSpinner variant="secondary" />
        <h3 className="font-fancy text-gray-500">Please wait...</h3>
      </div>
    );
  }

  if (!client) {
    return <Error404 />;
  }

  const { name, src, link, alt, experiences } = client;

  return (
    <div className="flex flex-wrap flex-col gap-8 justify-center">
      <Header name={name} link={link} src={src} alt={alt} />
      <div className="flex flex-col gap-16">
        {experiences.map(
          ({ id, role, startDate, endDate, project, description, solutions, skills }) => (
            <div className="flex flex-col gap-4" key={id}>
              <Role role={role} project={project} startDate={startDate} endDate={endDate} />
              <div className="flex flex-wrap flex-col gap-4 text-justify ">
                <Experience description={description} />
                <SolutionsList solutions={solutions} />
                <div className="flex flex-wrap flex-row gap-4">
                  {skills.map((skill, index) => (
                    <Chip key={index} text={skill} />
                  ))}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

Client.displayName = 'Client';

export default Client;
