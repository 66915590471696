import React from 'react';
import PropTypes from 'prop-types';

const Role = (props) => {
  const { role, project, startDate, endDate } = props;
  return (
    <div className="flex flex-wrap flex-row gap-2 md:gap-8 text-xl sm:gap-8 ">
      <div className="flex flex-col justify-center md:justify-start">
        <h2 className="text-2xl md:text-3xl text-primary font-black">{role}</h2>
        <h3 className="text-xl md:text-2xl font-bold">{project}</h3>
      </div>
      <p className="text-xl text-gray-500  leading-none sm:ml-auto">
        {startDate} - {endDate}
      </p>
    </div>
  );
};

Role.propTypes = {
  role: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

Role.displayName = 'Role';

export default Role;
