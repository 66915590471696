import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ContainedButton = (props) => {
  const { children, isSecondary, isWhite, onClick, disabled, size, fullWidth, ...otherProps } =
    props;
  const isPrimary = !isSecondary && !isWhite;

  return (
    <button
      className={cn('font-fancy rounded-md shadow-lg focus:outline-none focus:shadow-outline', {
        'px-4 py-2': size === 'small',
        'px-8 py-4': size === 'normal',
        'w-full': fullWidth,
        'bg-white-darker': disabled,
        'transform transition hover:scale-105 focus:scale-105 duration-300 ease-in-out': !disabled,
        'bg-primary hover:bg-primary-light focus:bg-primary-light text-white':
          isPrimary && !disabled,
        'bg-secondary hover:bg-secondary-light focus:bg-secondary-light text-black':
          isSecondary && !disabled,
        'bg-white hover:bg-white-darker focus:bg-white-darker text-black': isWhite && disabled,
      })}
      onClick={onClick}
      disabled={disabled}
      type="button"
      {...otherProps}
    >
      {children}
    </button>
  );
};

ContainedButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  isSecondary: PropTypes.bool,
  isWhite: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  fullWidth: PropTypes.bool,
};

ContainedButton.defaultProps = {
  isSecondary: false,
  isWhite: false,
  onClick: () => {},
  disabled: false,
  size: 'normal',
  fullWidth: false,
};

ContainedButton.displayName = 'ContainedButton';

export default ContainedButton;
