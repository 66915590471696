import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';
import { getPoliciesLinks } from '../../utility/utils';

const Footer = () => {
  const { isRo } = useContext(AppContext);
  const currentYear = new Date().getFullYear();
  const { privacyPolicyLink, cookiesPolicyLink } = useMemo(() => getPoliciesLinks(isRo), [isRo]);
  const { text: privacyPolicyText, link: privacyPolicyRoute } = privacyPolicyLink;
  const { text: cookiesPolicyText, link: cookiesPolicyRoute } = cookiesPolicyLink;

  return (
    <footer className="bg-primary/[.95]">
      <div className="container mx-auto py-16 flex flex-col gap-16 md:gap-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-16 md:gap-32">
          <div className="w-full md:w-1/3 flex flex-col gap-4">
            <div>
              <img
                className="mx-auto md:mx-0"
                src="images/dct-logo-white.png"
                width={150}
                alt="DacianTech horizontal Logo"
              />
            </div>
            <p className="text-center md:text-left text-base text-white mx-auto lg:mx-0 max-w-lg">
              {isRo
                ? 'Echipa noastră este întotdeauna pregătită pentru noi provocări. Ne place să ne dezvoltăm și să ne depășim limitele.'
                : 'Our team is always ready for new challenges. We like to grow and push our limits further.'}
            </p>
          </div>
          <div className="w-full md:w-1/3 flex flex-col gap-4">
            <p className="text-center text-xl text-white font-bold">
              {isRo ? 'Rețele sociale' : 'Social media'}
            </p>
            <div className="flex gap-8 justify-center md:items-center">
              <a
                className="flex items-center"
                href="https://www.facebook.com/daciantech/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="hover:scale-105 fa-brands fa-facebook text-4xl text-white" />
              </a>
              <a
                className="flex items-center"
                href="https://linkedin.com/company/daciantech"
                target="_blank"
                rel="noreferrer"
              >
                <i className="hover:scale-105 fa-brands fa-linkedin text-4xl text-white" />
              </a>
              <a
                className="flex items-center"
                href="https://join.skype.com/invite/jauoYf4Vqsdv"
                target="_blank"
                rel="noreferrer"
              >
                <i className="hover:scale-105 fa-brands fa-skype text-4xl text-white" />
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/3 flex justify-center md:justify-end">
            <div>
              <div className="flex flex-col items-center justify-center gap-4">
                <p className="text-center text-xl text-white font-bold">
                  {isRo ? 'Termeni și Condiții' : 'Terms of Service'}
                </p>
                <Link className="text-white hover:underline" to={privacyPolicyRoute}>
                  {privacyPolicyText}
                </Link>
                <Link className="text-white hover:underline" to={cookiesPolicyRoute}>
                  {cookiesPolicyText}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="text-white text-lg font-mono italic w-full text-center">
          Ⓒ {currentYear} {isRo ? 'Toate drepturile rezervate' : 'All rights reserved'}.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
