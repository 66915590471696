import React from 'react';
import PropTypes from 'prop-types';

const Body = (props) => {
  const { children, isHomepage } = props;

  if (isHomepage) {
    return children;
  }

  return <div className="container mx-auto flex-1 mt-navigation py-8">{children}</div>;
};

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isHomepage: PropTypes.bool.isRequired,
};

export default Body;
