import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from '../contexts/AppContext';

const ProtectedRoute = (props) => {
  const { children, ...otherProps } = props;
  const { isAuthenticated } = useContext(AppContext);

  return (
    <Route
      {...otherProps}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ProtectedRoute.defaultProps = {
  isAuthenticated: false,
};

ProtectedRoute.displayName = 'ProtectedRoute';

export default ProtectedRoute;
