import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Card = (props) => {
  const { className, title, subtitle, imageUrl, children, isWhiteTheme } = props;

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      <div className="flex gap-4 items-center">
        {imageUrl && (
          <div
            className={cn('rounded-full border-2 ', {
              'border-primary': !isWhiteTheme,
              'border-secondary-dark': isWhiteTheme,
            })}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: '100%',
              width: '6rem',
              height: '6rem',
            }}
          />
        )}
        <div>
          <p
            className={cn('font-fancy text-3xl text-primary font-bold', {
              'text-secondary-light': isWhiteTheme,
            })}
          >
            {title}
          </p>
          {subtitle && (
            <p
              className={cn('font-mono text-xl', {
                'text-gray-500': !isWhiteTheme,
                'text-gray-300': isWhiteTheme,
              })}
            >
              {subtitle}
            </p>
          )}
        </div>
      </div>
      <p className={cn({ 'text-white': isWhiteTheme })}>{children}</p>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isWhiteTheme: PropTypes.bool,
};

Card.defaultProps = {
  className: '',
  imageUrl: null,
  title: null,
  subtitle: null,
  isWhiteTheme: false,
};

Card.displayName = 'Card';

export default Card;
