import React from 'react';
import PropTypes from 'prop-types';

const Chip = (props) => {
  const { text } = props;
  return (
    <div className="flex items-center bg-secondary text-black-lighter px-4 py-2 rounded-lg text-sm">
      {text}
    </div>
  );
};

Chip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Chip;
